import React, {useState, useEffect} from 'react';
import styled from "styled-components";

import brk from '../../util/breakpoints';

import ContactSVG from "../../img/contact.svg";

export const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    padding: 3rem;
    background: var(--purple);
    overflow: hidden;
`;

export const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 5rem;
    transition: transform 0.33s, opacity 0.5s;
    transform: translateX(${(props) => (props.on ? '0%' : '-30%')});
    opacity: ${(props) => (props.on ? '1' : '0')};
    flex: 16;
    @media ${brk.device.tablet}{
        display: none;
    }
    @media ${brk.device.pc}{
        display: none;
    }
`;

export const LeftSVGBox = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    img {
        position: absolute;
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`;

export const Mid = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media ${brk.device.tablet}{
        display: none;
    }
    @media ${brk.device.pc}{
        display: none;
    }
`;

export const MidDivider = styled.div`
    background: var(--white);
    border-radius: 50px;
    height: ${(props) => (props.on ? '80%' : '0%')};
    width: 4px;
    transition: height 0.5s;
`;

export const Right = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    transition: transform 0.33s, opacity 0.5s;
    transform: translateX(${(props) => (props.on ? '0%' : '30%')});
    opacity: ${(props) => (props.on ? '1' : '0')};
    flex: 16;
    @media ${brk.device.pc}{
        padding: 1rem;
    }
    @media ${brk.device.tablet}{
        width: 100%;
        height: 100%;
        padding: 1rem;
        overflow: hidden;
    }
`;

export const FormContainer = styled.form`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const InputBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 360px;
    margin: 1rem 0;
    @media ${brk.device.tablet}{
        width: 100%;
        margin: 0.5rem 0;
    }

    label {
        color: var(--white);
        font-family: var(--fontReg);
        font-size: var(--fontSmall);
        font-weight: var(--fontWeightBold);
        padding-left: 1rem;
        user-select: none;
    }
    
    input {
        outline: none;
        border: none;
        border-radius: 100px;
        height: 40px;
        padding-left: 1rem;
        font-family: var(--fontReg);
        font-size: var(--fontTiny);
        font-weight: var(--fontWeightReg);
        background: var(--white);
        transition: all 0.25s;
        &:hover {
            transform: translateY(-3%);
            box-shadow: var(--boxShadowBasic);
        }
        @media ${brk.device.tablet}{
            font-size: 1rem;
        }
        
    }

    textarea {
        outline: none;
        border: none;
        border-radius: 10px;
        padding-left: 1rem;
        padding-top: 8px;
        padding-right: 8px;
        font-family: var(--fontReg);
        font-size: var(--fontTiny);
        font-weight: var(--fontWeightReg);
        background: var(--white);
        transition: all 0.25s;
        &:hover {
            transform: translateY(-1%);
            box-shadow: var(--boxShadowBasic);
        }
        @media ${brk.device.tablet}{
            font-size: 1rem;
        }
    }
`;

export const SubmitBtn = styled.div`
    width: 30%;
    height: 60px;
    margin-top: 2rem;
    transition: all 0.25s;
    button {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        background: transparent;
        color: var(--white);
        border: var(--white) 2px solid;
        border-radius: 100px;
        font-family: var(--fontReg);
        font-weight: var(--fontWeightBold);
        font-size: var(--fontMed);
        transition: all 0.25s;
        cursor: pointer;

        &:hover {
            transform: translateY(-5%);
            box-shadow: var(--boxShadowBasic);
            text-shadow: var(--textShadowBasic);
        }
        @media ${brk.device.tablet}{
            font-size: var(--fontSmall);
        }
    }
    @media ${brk.device.tablet}{
        width: 75%;
    }
`;


function Contact() {

    const [contactIsOn, setContactIsOn] = useState(0);


    const listener = e => {
        let y = window.scrollY;
        let maxH = document.body.scrollHeight - window.innerHeight;
        let curScroll = y / maxH;
        if (curScroll >= .90) { // Contact Section
            if (!contactIsOn) {
                setContactIsOn(1);
            }
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    });

    return (
        <>
            <Wrapper id="contact">
                <Content>
                    <Left on={contactIsOn}>
                        <LeftSVGBox>
                            <img alt='contactmeSVG' src={ContactSVG} />
                        </LeftSVGBox>
                        
                    </Left>
                    <Mid>
                        <MidDivider on={contactIsOn}/>
                    </Mid>
                    <Right on={contactIsOn}>
                        {/* Purely for FormSubmit.co to read from. */}
                        <input type="hidden" name="_subject" value="Somebody wants to talk!"></input>
                        <input type="hidden" name="_captcha" value="true"></input>

                        <FormContainer 
                            action='https://formsubmit.co/776e9b09784a24f9f5dc225384791a43'
                            method='POST'
                        >
                            <InputBox>
                                <label htmlFor="fName">First Name</label>
                                <input type="text" name="fName" id="fName" required=""></input>
                            </InputBox>
                            <InputBox>
                                <label htmlFor="lName">Last Name</label>
                                <input type="text" name="lName" id="lName" required=""></input>
                            </InputBox>
                            <InputBox>
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" required=""></input>
                            </InputBox>
                            <InputBox>
                                <label htmlFor="notes">Message</label>
                                <textarea cols="50" rows="4" placeholder="Don't over think it! Just shoot.." id="notes" name="notes"></textarea>
                            </InputBox>
                            <SubmitBtn>
                                <button type="submit">Submit</button>
                            </SubmitBtn>
                        </FormContainer>
                    </Right>
                </Content>
            </Wrapper>
        </>
    );
};

export default Contact;
