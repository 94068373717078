import React, { Suspense } from 'react';
import { Canvas } from "@react-three/fiber";
import { Loader } from "@react-three/drei";
import styled from "styled-components";


// comps
import HomePageScene from "../components/3JS/HomePageScene";
import Hero from "../components/Hero";
import HeroArrow from '../components/HeroArrow';
import About from "../components/About";
import Work from "../components/Work";
import Contact from "../components/Contact";
import ContentModal from '../components/ContentModal';


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1000;
`;

const Content = styled.div`
    font-family: var(--fontReg);
`;

const CanvasContainer = styled.div`
    width: 100%;
    height: 100vh;
`;




function Home() {

    const [showingContentModal, setShowingContentModal] = React.useState(false);
    const [displayedContent, setDisplayedContent] = React.useState({
        img: null,
        isVideo: false,
        videoUrl: null,
        title: "",
    });

    return (
        <>
            <Wrapper>
                <Content>
                    {   showingContentModal &&
                        <ContentModal 
                            displayedContent={displayedContent}
                            setShowingContentModal={setShowingContentModal}
                        />
                    }
                    <Hero />
                    <HeroArrow />
                    <CanvasContainer id="home">
                        <Canvas
                            shadows
                            shadowMap
                            camera={{ 
                                position: [0, 0, 0], 
                                fov: 90
                            }}
                        >
                            <Suspense fallback={null}>
                                <fog attach='fog' args={["#252934", 15, 50]} />
                                <HomePageScene />
                            </Suspense>
                        </Canvas>
                        <Loader />
                    </CanvasContainer>
                    <About />
                    <Work 
                        setShowingContentModal={setShowingContentModal}
                        showingContentModal={showingContentModal}
                        displayedContent={displayedContent}
                        setDisplayedContent={setDisplayedContent}
                    />
                    <Contact />
                </Content>
            </Wrapper>
        </>
    );
};

export default Home;
