import React, { useState, useEffect } from 'react';
import {
    Wrapper,
    Content,
    NavLinkBox,
    NavLink
} from './Nav.styles';

const Nav = () => {

    // Our Colors
    const purple = "#6317ff";
    const lime = "#b3ff17";
    const white = "#fff";
    const black = "#000";
    
    // Check if we're at the top of the page.
    const [linkColor, setLinkColor] = useState(purple);
    const [linkHoverColor, setLinkHoverColor] = useState(purple);
    const [navActive, setNavActive] = useState(false);


    const listener = e => {
        let y = window.scrollY;
        let maxH = document.body.scrollHeight - window.innerHeight;
        let curScroll = y / maxH;
        if (curScroll < .10) { // Not passed first section.
            if (navActive) {
                setNavActive(false);
            }
        } else { // Is passed first section.
            if (!navActive) {
                setNavActive(true);
            }

            if (curScroll >= .10 && curScroll < .25) { // About Section
                if (linkColor !== purple) {
                    setLinkColor(purple);
                }
                if (linkHoverColor !== black) {
                    setLinkHoverColor(black);
                }
            } else if (curScroll >= .25 && curScroll < .925) { // Work
                if (linkColor !== lime) {
                    setLinkColor(lime);
                }
                if (linkHoverColor !== purple) {
                    setLinkHoverColor(purple);
                }
            } else if (curScroll >= .925) { // Contact
                if (linkColor !== white) {
                    setLinkColor(white);
                }
                if (linkHoverColor !== lime) {
                    setLinkHoverColor(lime);
                }
            }
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    });

    return (
        <Wrapper>
            <Content>
                <NavLinkBox navOn={navActive} d={0}>
                    <NavLink 
                    href='#home'
                    linkClr={linkColor} 
                    linkHvrClr={linkHoverColor}
                    >Home</NavLink>
                </NavLinkBox>
                <NavLinkBox navOn={navActive} d={0.075}>
                    <NavLink 
                    href='#about'
                    linkClr={linkColor} 
                    linkHvrClr={linkHoverColor}
                    >About</NavLink>
                </NavLinkBox>
                <NavLinkBox navOn={navActive} d={0.125}>
                    <NavLink 
                    href='#work'
                    linkClr={linkColor} 
                    linkHvrClr={linkHoverColor}
                    >Work</NavLink>
                </NavLinkBox>
                <NavLinkBox navOn={navActive} d={0.175}>
                    <NavLink 
                    href='#contact'
                    linkClr={linkColor} 
                    linkHvrClr={linkHoverColor}
                    >Contact</NavLink>
                </NavLinkBox>
            </Content>
        </Wrapper>
    );
};

export default Nav;
