import React from 'react';
import styled from "styled-components";
import errorSVG from "../img/error.svg";

// comps


const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`;
const Content = styled.div`
    width: 100%;
    height: 100%;
`;
const SVGContainer = styled.div`
    padding: 5rem;
    width: 100%;
    height: 100%;
    position: relative;
    img {
        position: absolute;
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
    }
    div {
        border: var(--lime) 4px double;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: var(--fontReg);
        height: 100%;
        width: 100%;
        padding: 5rem;
        h1 {
            color: white;
            z-index: 5;
            font-size: var(--fontMassive);
            font-weight: var(--fontWeightBold);
            margin-bottom: 2rem;
        }
        h3 {
            color: white;
            z-index: 5;
            font-size: var(--fontSuperBig);
        }
        a {
            color: var(--white);
            text-decoration: underline;
            font-size: var(--fontTiny);
            z-index: 5;
        }
    }
`;

function NotFound() {

    return (
        <>
            <Wrapper>
                <Content>
                    <SVGContainer>
                        <img src={errorSVG} alt='404 error.'></img>
                        <div>
                            <h1>404</h1>
                            <h3>How'd you get in here?</h3>
                            <a href='/'>Let's get you back to safety.</a>
                        </div>
                    </SVGContainer>
                </Content>
            </Wrapper>
        </>
    );
};

export default NotFound;
