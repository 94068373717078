import React, { Suspense } from 'react';
import { useFrame } from '@react-three/fiber';
import { Stars } from '@react-three/drei';


const HomePageScene = () => {

    // Ref

    // Utility

    // Helpers

    // Panning vars
    // let currentMouseX = window.innerWidth / 2; // initially set to middle.
    // let aimMouseX = window.innerWidth / 2; // initially set to middle.

    useFrame(({ camera }) => {

        // Rotate our cylinder

        // // Vars for camera panning.
        // const panDamp = 0.05;
        // const panDist = 0.002;
        
        // // Pans Camera Left/Right Based On MouseX.
        // currentMouseX += (aimMouseX - currentMouseX) * panDamp;
        // const pan = currentMouseX * panDist;
        // camera.position.x = pan;

        // Forces camera to look at a specific point in 3D space.
        camera.rotation.y += 0.00033;
        camera.rotation.x += 0.00015;
        camera.rotation.z += 0.00033;
    });
    // window.addEventListener("mousemove",(event)=>{aimMouseX = event.pageX - window.innerWidth / 2});


    return (
        <group>
            {/* Utility */}
            <Stars radius={100} depth={50} count={5000} factor={5} saturation={0} fade />

            {/* Lighting */}
            {/* <pointLight castShadow args={["#fff", 1, 100]} position={[0, 3, 5]} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
            <ambientLight intensity={0.5} /> */}

            {/* Objects */}
            <Suspense fallback={null}>

            </Suspense>
        </group>
    );
}

export default HomePageScene;
