import React from 'react';
import styled from "styled-components";

// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -100%);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 501;
    overflow: hidden;
    p {
        color: var(--white);
        font-size: var(--fontSmall);
        font-weight: var(--fontWeightReg);
        font-family: var(--fontReg);
        transform: scaleX(1.5);
        text-align: center;
        &:hover {
            cursor: pointer;
        }
    }
`;

const TitleContainer = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 85vw;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    p {
        color: var(--white);
        font-family: var(--fontReg);
        font-size: var(--fontTiny);
        font-weight: var(--fontWeightReg);
        text-align: center;
    }
`;

// const BackButton = styled.div`
//     position: absolute;
//     top: 50%;
//     left: 0;
//     transform: translate(-150%, -100%);
//     width: 32px;
//     height: 32px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 501;
//     overflow: hidden;
//     &:hover {
//         cursor: pointer;
//     }
// `;

// const NextButton = styled.div`
//     position: absolute;
//     top: 50%;
//     right: 0;
//     transform: translate(150%, -100%);
//     width: 32px;
//     height: 32px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 501;
//     overflow: hidden;
//     &:hover {
//         cursor: pointer;
//     }
// `;

const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--transBlackStrong);
    z-index: 500;
`;

const Content = styled.div`
    position: relative;
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--transBlackStrong);
    border: 1px solid var(--lime);
    z-index: 500;
`;

const VideoFrame = styled.iframe`
    width: 100%;
    height: 100%;
    padding: 0.1rem;
    z-index: 770;
`;

const ImageFrame = styled.div`
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    background-image: url(${props => props.image});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 770;
`;



function ContentModal(props) {

    return (
        <Wrapper
            onClick={(e) => {
                e.preventDefault();
                if (e.target === e.currentTarget) {
                    props.setShowingContentModal(false);
                }
            }}
            >
            <Content
                handleClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <CloseButton 
                    onClick={(e) => {
                        e.preventDefault();
                        props.setShowingContentModal(false);
                    }}
                
                >
                    <p>X</p>
                </CloseButton>
                <TitleContainer>
                    <p>{props.displayedContent.title}</p>
                </TitleContainer>
                {/* <BackButton 
                    onClick={(e) => {
                        e.preventDefault();
                        props.setShowingContentModal(false);
                    }}
                
                >
                    <FaChevronLeft color={'white'} size={'2rem'}/>
                </BackButton>
                <NextButton 
                    onClick={(e) => {
                        e.preventDefault();
                        props.setShowingContentModal(false);
                    }}
                
                >
                    <FaChevronRight color={'white'} size={'2rem'}/>
                </NextButton> */}
                {
                    props.displayedContent.isVideo === true
                    ?
                    <VideoFrame controls src={props.displayedContent.videoUrl} />
                    :
                    <ImageFrame image={props.displayedContent.img} />
                }
            </Content>
        </Wrapper>
    );
};

export default ContentModal;
