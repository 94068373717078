import React from "react";
import "./App.css";
import { GlobalStyle } from "./GlobalStyle";

// comps
import Nav from "./components/Nav";
import AllRoutes from "./pages/Routes";

function App() {

  return (
    <>
        <Nav />
        <AllRoutes />
        <GlobalStyle />
    </>
  );
}

export default App;
