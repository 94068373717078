import React from 'react';
import styled from "styled-components";

import { FaTools, FaRegImage, FaYoutube } from "react-icons/fa";

import brk from '../../util/breakpoints';

export const Wrapper = styled.div`
    width: 100%;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: transparent;
    border: 1px solid var(--white);
    box-shadow: var(--boxShadowBasic);
    transition: all 0.3s ease-in-out;
    z-index: 53;
    /* @media ${brk.device.tablet}{
        height: 500px;
    }
    @media ${brk.device.pc}{
        height: 500px;
    } */
`;

export const Content = styled.div`
    height: 350px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 1.5rem;
    @media ${brk.device.tablet}{
        flex-direction: column-reverse;
        padding: 0.5rem 1.5rem;
        height: 550px;
    }
    @media ${brk.device.pc}{
        flex-direction: column-reverse;
        padding: 0.5rem 1.5rem;
        height: 550px;
    }
`;
export const ExtendedContent = styled.div`
    height: ${(props) => (props.expandedWorkItem === props.workItemIndex ? '700px' : '0px')};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    transition: all 0.5s;
    opacity: ${(props) => (props.expandedWorkItem === props.workItemIndex ? 1 : 0)};
    @media ${brk.device.tablet}{
        height: ${(props) => (props.expandedWorkItem === props.workItemIndex ? '1080px' : '0px')};
    }
    @media ${brk.device.pc}{
        height: ${(props) => (props.expandedWorkItem === props.workItemIndex ? 'fit-content' : '0px')};
    }
`;

export const ExtendedContentTopDivider = styled.div`
    height: 1px;
    width: ${(props) => (props.expandedWorkItem === props.workItemIndex ? '100%' : '0%')};
    background: var(--white);
    border-radius: 5px;
    transition: all 1s;
`;

export const ExtendedContentBottom = styled.div`
    height: 100%;
    width: 100%;
    padding: 1.5rem 0;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    @media ${brk.device.tablet}{
        flex-direction: column;
    }
    @media ${brk.device.pc}{
        flex-direction: column;
    }
`;

export const ExtendedContentBottomLeft = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: transform 0.5s, opacity 0.1s;
    transform: ${(props) => (props.expandedWorkItem === props.workItemIndex ? 'translateX(0px)' : 'translateX(-600px)')};
    opacity: ${(props) => (props.expandedWorkItem === props.workItemIndex ? 1 : 0)};
`;

export const ExtendedContentBottomLeftHeader = styled.div`
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 2px solid var(--lime);
    h1 {
        font-size: var(--fontBig);
        font-family: var(--fontReg);
        font-weight: var(--fontWeightReg);
        color: var(--white);      
        text-align: center;
        white-space: nowrap;  
    }
`;

export const ExtendedContentBottomLeftListContainer = styled.div`
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    h2 {
        font-size: var(--fontTiny);
        font-family: var(--fontReg);
        font-weight: var(--fontWeightThin);
        color: var(--white);    
        padding-bottom: 0.75rem;
    }
    ul {
        list-style: square;
        filter: ${props => (
            props.workItemIndex === 1 ||
            props.workItemIndex === 9 ||
            props.workItemIndex === 10 ||
            props.workItemIndex === 11 ||
            props.workItemIndex === 12
        ) ? 'blur(8px)' : 'none'};
        li {
            font-size: var(--fontSmall);
            font-family: var(--fontReg);
            font-weight: var(--fontWeightReg);
            color: var(--white);  
            p {
                span {
                    user-select: none;
                }
            }
        }
    }
`;

export const ExtendedContentBottomRight = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: opacity 0.5s;
    transition-delay: 0.66s;
    opacity: ${props => props.expandedWorkItem === props.workItemIndex ? 1 : 0};
    overflow-y: auto;
    overflow-x: hidden;
    border: 2px solid var(--yellow);
    &::-webkit-scrollbar {
        width: 4px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--lime);
        width: 2px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: var(--transBlack);
        border-radius: 5px;
    }
`;

export const ExtendedContentBottomRightTextContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0rem 4rem;
    h1 {
        font-size: var(--fontMed);
        font-family: var(--fontBold);
        font-weight: var(--fontWeightReg);
        color: var(--lime);  
        text-align: center;
    }
    p {
        font-family: var(--fontThin);
        font-weight: var(--fontWeightReg);
        color: var(--white);  
        text-align: center;
    }
    @media ${brk.device.tablet}{
        padding: 0rem 2rem;
    }
    @media ${brk.device.pc}{
        padding: 0rem 1rem;
    }
`;

export const ExtendedContentBottomRightImageContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 2rem;
    flex: 1;
    h1 {
        font-size: var(--fontMed);
        font-family: var(--fontBold);
        font-weight: var(--fontWeightReg);
        color: var(--lime);  
        text-align: center;
    }
    @media ${brk.device.tablet}{
        padding: 0rem 1rem;
    }
    @media ${brk.device.pc}{
        padding: 0rem 1rem;
    }
`;
export const ExtendedContentBottomRightImageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
`;


export const ExtendedContentBottomRightImage = styled.div`
    position: relative;
    width: 128px;
    height: 128px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid var(--white);
    &:hover {
        cursor: pointer;
        -webkit-filter: grayscale(1); 
        -webkit-filter: grayscale(100%); 
        -moz-filter: grayscale(100%);
        filter: gray; 
        filter: grayscale(100%);
        opacity: 0.75;
    }
`;

export const ExtendedContentBottomRightImageTypeContainer = styled.div`
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: 1px;
    right: 5px;
    z-index: 9999;
    overflow: hidden;
`;

export const Left = styled.div`
    height: 100%;
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media ${brk.device.tablet}{
        height: 30%;
        width: 100%;
    }
    @media ${brk.device.pc}{
        height: 30%;
        width: 100%;
    }
`;

export const LeftTitle = styled.div`
    display: flex;
    h1 {
        font-size: var(--fontKindaMassive);
        font-family: var(--fontReg);
        font-weight: var(--fontWeightReg);
        color: var(--white);
        text-align: center;
        @media ${brk.device.tablet}{
            font-size: var(--fontBig);
        }
        @media ${brk.device.pc}{
            font-size: var(--fontBig);
        }
    }
    @media ${brk.device.tablet}{
        display: none;
    }
    @media ${brk.device.pc}{
        display: none;
    }
`;

export const LeftUrl = styled.div`
    display: flex;
    padding: 0.1rem 0;
    a {
        font-size: var(--fontTiny);
        font-family: var(--fontReg);
        font-weight: var(--fontWeightReg);
        color: var(--white);
        text-align: center;
        opacity: 0.66;
        @media ${brk.device.tablet}{
            font-size: var(--fontTiny);
        }
        @media ${brk.device.pc}{
            font-size: var(--fontTiny);
        }
    }
    @media ${brk.device.tablet}{
        display: none;
    }
    @media ${brk.device.pc}{
        display: none;
    }
`;

export const LeftLifetime = styled.div`
    display: flex;
    padding: 0.1rem 0;
    p {
        font-size: 1rem;
        font-family: var(--fontReg);
        font-weight: var(--fontWeightReg);
        color: var(--white);
        text-align: center;
        opacity: 0.5;
    }
    @media ${brk.device.tablet}{
        display: none;
    }
    @media ${brk.device.pc}{
        display: none;
    }
`;

export const LeftShortDesc = styled.div`
    display: flex;
    padding-bottom: 0.5rem;
    p {
        font-size: var(--fontTiny);
        font-family: var(--fontReg);
        font-weight: var(--fontWeightReg);
        color: var(--white);
        text-align: center;
        @media ${brk.device.tablet}{
            font-size: var(--fontTiny);
        }
        @media ${brk.device.pc}{
            font-size: var(--fontTiny);
        }
    }
    @media ${brk.device.tablet}{
        flex: 1;
        font-weight: var(--fontWeightThin);
    }
    @media ${brk.device.pc}{
        flex: 1;
        font-weight: var(--fontWeightThin);
    }
`;

export const LeftLearnMoreBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => (props.expandedWorkItem === props.workItemIndex ? '0px' : '60px')};
    width: 40%;
    border: 1px solid var(--white);
    overflow: hidden;
    transition: all 0.3s;
    background: linear-gradient(rgba(255,255,255,0) 60%, rgba(255,255,255,0.15));
    opacity: ${(props) => (props.expandedWorkItem === props.workItemIndex ? 0 : 1)};
    h3 {
        color: var(--white);
        font-size: var(--fontTiny);
        font-weight: var(--fontWeightReg);
        font-family: var(--fontReg);
        text-align: center;
        white-space: nowrap;
    }
    &:hover {
        background: linear-gradient(rgba(255,255,255,0) 20%, rgba(255,255,255,0.15));
        cursor: pointer;
    }
    @media ${brk.device.tablet}{
        width: 80%;
        height: ${(props) => (props.expandedWorkItem === props.workItemIndex ? '0px' : '80px')};
        flex: 1;
    }
    @media ${brk.device.pc}{
        width: 60%;
        height: ${(props) => (props.expandedWorkItem === props.workItemIndex ? '0px' : '80px')};
        flex: 1;
    }
`;

export const Right = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: row;
    @media ${brk.device.tablet}{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 650%;
        width: 100%;
    }
    @media ${brk.device.pc}{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 65%;
        width: 100%;
    }
`;

export const RightTitle = styled.div`
    display: flex;
    h1 {
        font-size: var(--fontKindaMassive);
        font-family: var(--fontReg);
        font-weight: var(--fontWeightReg);
        color: var(--white);
        text-align: center; 
        @media ${brk.device.tablet}{
            font-size: var(--fontBig);
        }
        @media ${brk.device.pc}{
            font-size: var(--fontBig);
        }
    }
    
    @media ${brk.device.tablet}{
        flex: 1;
    }
    @media ${brk.device.pc}{
        flex: 1
    }
    @media (min-width: 1201px) {
        display: none;
    }
`;

export const ImageContainer = styled.div`
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${props => props.src});
    border: 1px solid var(--white);
    @media ${brk.device.tablet}{

    }
    @media ${brk.device.pc}{

    }
`;

function WorkItem(props) {

    return (
        <>
            <Wrapper>
                <Content>
                    <Left>
                        <LeftTitle>
                            <h1>{props.title}</h1>
                        </LeftTitle>
                        {props.url && 
                            <LeftUrl>
                                <a href={`https://${props.url}/`} target={'_blank'} rel={"noreferrer"}>{props.url}</a>
                            </LeftUrl>
                        }
                        <LeftShortDesc>
                            <p>{props.shortDesc}</p>
                        </LeftShortDesc>
                        <LeftLearnMoreBtn
                            onClick={() => {
                                props.setExpandedWorkItem(props.workItemIndex);
                            }}
                            expandedWorkItem={props.expandedWorkItem}
                            workItemIndex={props.workItemIndex}
                        >
                            <h3>Learn More</h3>
                        </LeftLearnMoreBtn>
                    </Left>
                    <Right>
                        <RightTitle>
                            <h1>{props.title}</h1>
                        </RightTitle>
                        <ImageContainer src={props.src} />
                    </Right>
                </Content>
                <ExtendedContent
                    expandedWorkItem={props.expandedWorkItem}
                    workItemIndex={props.workItemIndex}
                >
                    <ExtendedContentTopDivider
                        expandedWorkItem={props.expandedWorkItem}
                        workItemIndex={props.workItemIndex}
                    />
                    <ExtendedContentBottom>
                        <ExtendedContentBottomLeft
                            expandedWorkItem={props.expandedWorkItem}
                            workItemIndex={props.workItemIndex}
                        >
                            <ExtendedContentBottomLeftHeader>
                                <FaTools size={'5rem'} color={'white'} />
                                <h1>Tools Used</h1>
                            </ExtendedContentBottomLeftHeader>
                            <ExtendedContentBottomLeftListContainer
                                expandedWorkItem={props.expandedWorkItem}
                                workItemIndex={props.workItemIndex}
                            >
                                {(
                                    props.workItemIndex === 1 ||
                                    props.workItemIndex === 9 ||
                                    props.workItemIndex === 10 ||
                                    props.workItemIndex === 11 ||
                                    props.workItemIndex === 12
                                ) && <h2>{`< Top Secret >`}</h2>}
                                <ul>
                                {   (props.workItemIndex !== 1 && props.workItemIndex !== 9) 
                                    ?
                                        props.toolsUsed.map((tool, index) => {
                                            return (
                                                <li key={index}>
                                                    <p>{tool}</p>
                                                </li>
                                            )
                                        })
                                    :
                                        props.toolsUsed.map((tool, index) => {
                                            return (
                                                <li key={index}>
                                                    <p><span>{tool}</span></p>
                                                </li>
                                            )
                                        })
                                }
                                </ul>
                            </ExtendedContentBottomLeftListContainer>
                        </ExtendedContentBottomLeft>
                        <ExtendedContentBottomRight
                            expandedWorkItem={props.expandedWorkItem}
                            workItemIndex={props.workItemIndex}
                        >
                            <ExtendedContentBottomRightTextContent>
                                <h1>Quest</h1>
                                <p>{props.questText}</p>
                            </ExtendedContentBottomRightTextContent>
                            <ExtendedContentBottomRightTextContent>
                                <h1>Loot</h1>
                                <p>{props.lootText}</p>
                            </ExtendedContentBottomRightTextContent>
                            <ExtendedContentBottomRightImageContent>
                                <h1>Content</h1>
                                <ExtendedContentBottomRightImageContainer>
                                    {
                                        props.contentData.map((item) => {
                                            return <ExtendedContentBottomRightImage 
                                                key={Math.floor(Math.random() * 100000)}
                                                image={item.img}
                                                onClick={() => {
                                                    props.setShowingContentModal(true);
                                                    props.setDisplayedContent({
                                                        img: item.img,
                                                        isVideo: item.isVideo,
                                                        videoUrl: item.videoUrl,
                                                        title: item.title,
                                                    });
                                                }}
                                            >
                                                <ExtendedContentBottomRightImageTypeContainer>
                                                    {
                                                        item.isVideo ?
                                                            <FaYoutube size={'100%'} color={'white'} />
                                                            :
                                                            <FaRegImage size={'100%'} color={'white'} />
                                                    }
                                                </ExtendedContentBottomRightImageTypeContainer>
                                            </ExtendedContentBottomRightImage>
                                        })
                                    }
                                </ExtendedContentBottomRightImageContainer>
                            </ExtendedContentBottomRightImageContent>
                        </ExtendedContentBottomRight>
                    </ExtendedContentBottom>
                </ExtendedContent>
            </Wrapper>
        </>
    );
};

export default WorkItem;
