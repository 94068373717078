
// Here we're grabbing our global styling from style-components for react.
import { createGlobalStyle } from 'styled-components';

// Here we export it to all the other files AND build it via CSS so we can use these pre-built anywhere.
export const GlobalStyle = createGlobalStyle`

    :root {

        --red: #f10101;
        --blue: #13b3b7;
        --darkBrown: #634430;
        --lightBrown: #916446;
        
        --purple: #6317ff;
        --lime: #b3ff17;
        --darkGray: #252934;
        --white: #fff;
        --black: #000000;
        --transWhite: rgba(255,255,255,0.5);
        --transBlackWeak: rgba(0,0,0,0.3);
        --transBlack: rgba(0,0,0,0.6);
        --transBlackStrong: rgba(0,0,0,0.9);


        --fontMassive: 4rem;
        --fontKindaMassive: 3.5rem;
        --fontSuperBig: 3rem;
        --fontBig: 2.5rem;
        --fontMed: 2rem;
        --fontSmall: 1.5rem;
        --fontTiny: 1.25rem;

        --fontReg: 'Poppins', sans-serif;
        
        --fontWeightThin: 100;
        --fontWeightReg: 400;
        --fontWeightBold: 700;
        
        --textShadowBasic: 4px 4px 4px rgba(0,0,0,0.5);
        --boxShadowBasic: 4px 4px 8px 2px rgba(0,0,0,0.5);
        --boxShadowStrong: 0px 0px 12px 6px rgba(0,0,0,0.5);
        
    }
`;
