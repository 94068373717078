import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import brk from '../../util/breakpoints';


const HeroBox = styled.div`
    position: absolute; 
    left: 50%;
    top: 40%;
    opacity: 0;
    opacity: ${(props) => (props.init ? '1' : '0')};
    transition: opacity 0.4s, transform 0.2s;
    transition-delay: 0.5s;
    transform: translate(-50%, -50%);
    transform: translate(${(props) => (props.init ? '-50%, -50%' : '-50%, -120%')});
    width: 780px;
    height: 280px;
    display: flex;
    flex-direction: column;
    z-index: 100;
    user-select: none;
    @media ${brk.device.tablet} {
        width: 100%;
        padding: 6px;
    }
`;

const SubtleHeroText = styled.h3`
    font-family: var(--fontBold);
    color: rgba(255,255,255,0.40);
    letter-spacing: 3px;
    @media ${brk.device.tablet} {
        text-align: center;
    }
`;

const MainHeroText = styled.h3`
    font-family: var(--fontReg);
    font-weight: var(--fontWeightBold);
    font-size: var(--fontSuperBig);
    color: var(--white);
    span {
        color: var(--lime);
    }
    @media ${brk.device.tablet} {
        text-align: center;
    }
`;




function Hero() {
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        setInitialized(true);
    }, []);

    return (
        <>
            <HeroBox init={initialized}>
                <SubtleHeroText>
                    Innovate. Learn. Win.
                </SubtleHeroText>
                <MainHeroText>
                    Hi. My name is <span>James Spears</span>. I'm a Senior Software Engineer.
                </MainHeroText>
            </HeroBox>
            {
                // loop through array and find biggest value
            }
        </>
    );
};

export default Hero;
