const size = {
 phone: "320px",
 tablet: "768px",
 pc: "1200px",
}
const device = {
 phone: `(max-width: ${size.phone})`,
 tablet: `(max-width: ${size.tablet})`,
 pc: `(max-width: ${size.pc})`
}

const sd = {size, device}

export default sd;

/*
    import brk from '../../util/breakpoints';
    @media ${brk.device.phone}{
        display: none;
    }
*/