import React, { useEffect, useState } from 'react';
import styled, { keyframes } from "styled-components";

import brk from '../../util/breakpoints';

const anim = keyframes`
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
`;

export const Wrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    transition-delay: 1.5s;
    transition-duration: 0.25s;
    transition-property: opacity;
    opacity: ${(props) => (props.init ? '1' : '0')};
`;

export const Content = styled.div`
    position: absolute; 
    left: 50%;
    bottom: 33%;
    transform: translate(-50%, -50%);
    
    @media ${brk.device.tablet}{
        bottom: 10%;
    }

    span {
        display: block;
        width: 25px;
        height: 25px;
        border-bottom: white 1px solid;
        border-right: white 1px solid;
        transform: rotate(45deg);
        margin: -10px;
        animation: ${anim} 2s infinite;
    }
    span:nth-child(1) {
        animation-delay: -0.2s;
    }
    span:nth-child(2) {
        animation-delay: -0.4s;
    }
    span:nth-child(3) {
        animation-delay: -0.6s;
    }

`;



function HeroArrow() {

    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        setInitialized(true);
    }, []);

    return (
        <Wrapper init={initialized}>
            <Content>
                <span></span>
                <span></span>
                <span></span>
            </Content>
        </Wrapper>
    );
};

export default HeroArrow;
