import styled from 'styled-components';

import brk from '../../util/breakpoints';

export const Wrapper = styled.div`
    position: fixed;
    left: 0;
    top: 10%;
    z-index: 999;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    * {
        font-size: var(--fontTiny);
        font-family: var(--fontReg);
        font-weight: var(--fontWeightReg);
        @media ${brk.device.tablet}{
            font-size: 1rem;
        }
    }
`;

export const NavLinkBox = styled.div`
    padding: 0.5rem;
    transition-property: transform;
    transition-duration: 0.25s;
    transition-delay: ${props => `${props.d}s`};
    transform: translateX(${(props) => (props.navOn ? '0%' : '-200%')});
    
`;

export const NavLink = styled.a`
    color: ${(props) => (props.linkClr) || "white"};
    writing-mode: vertical-rl;
    user-select: none;
    text-decoration: none;
    transition: all 0.25s;
    &:hover {
        transform: scale(1.1);
        color: ${(props) => (props.linkHvrClr) || "#b3ff17"};
    }
`;
