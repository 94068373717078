import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import brk from '../../util/breakpoints';

// Background
import WorkSVG from "../../img/work.svg";

// Jetson Images
import jetsonLogo from "../../img/jetsonLogo.png";
import jetsonGamified from "../../img/jetsonGamified.png";
import jetsonChooseYourBusiness from "../../img/jetsonChooseYourBusiness.png";
import jetsonAstro from "../../img/jetsonAstro.png";

// Blockchains Images
import blockchainsHero from "../../img/blockchainsHero.png";
import blockchainsChargeTower from "../../img/blockchainsChargeTower.png";
import blockchainsGadgets from "../../img/blockchainsGadgets.png";
import blockchainsPeople from "../../img/blockchainsPeople.png";

// KeplerSavings Images
import keplerDashboard from "../../img/keplerDashboard.png";
import keplerFinalAmount from "../../img/keplerFinalAmount.png";
import keplerSpendingHabits from "../../img/keplerSpendingHabits.png";
import keplerStartInvesting from "../../img/keplerStartInvesting.png";

// GravyStack Images
import gravyStackImg from "../../img/gravystack.png";
import gravyStackWhatIs from "../../img/gravyStackWhatIs.png";
import gravyStackGuardians from "../../img/gravyStackGuardians.png";
import gravyStackHenchmen from "../../img/gravyStackHenchmen.png";
import gravyStackTestimonial from "../../img/gravyStackTestimonial.png";
import gravyStackMoneyMachine from "../../img/gravyStackMoneyMachine.png";
import gravyStackGigs from "../../img/gravyStackGigs.png";

// OSRSGuessr Images
import osrsguessrImg from "../../img/osrsguessr.png";
import osrsGuessrAboutPage from "../../img/osrsGuessrAboutPage.png";
import osrsGuessrHomePage from "../../img/osrsGuessrHomePage.png";
import osrsGuessrAuthedHomePage from "../../img/osrsGuessrAuthedHomePage.png";
import osrsGuessrSoloRunStatsPage from "../../img/osrsGuessrSoloRunStatsPage.png";
import osrsGuessrTellacon from "../../img/osrsGuessrTellacon.jpg";
import osrsGuessrJimmy from "../../img/osrsGuessrJimmy.jpg";

// PokerPower Images
import pokerpowerImg from "../../img/pokerpower.png";
import pokerpowerHomeScreen from "../../img/pokerpowerHomeScreen.png";
import pokerpowerChatOverlay from "../../img/pokerpowerChatOverlay.png";
import pokerpowerDontLeanIn from "../../img/pokerpowerDontLeanIn.png";
import pokerpowerTakeYourSeat from "../../img/pokerpowerTakeYourSeat.png";
import pokerpowerWinningHand from "../../img/pokerpowerWinningHand.png";
import pokerpowerLessonsAreHere from "../../img/pokerpowerLessonsAreHere.png";

// Proposterous Images
import proposterousImg from "../../img/proposterous.png";
import proposterousHomeScreen from "../../img/proposterousHomeScreen.jpg";
import proposterousFirstLevel from "../../img/proposterousFirstLevel.png";
import proposterousMicrowave from "../../img/proposterousMicrowave.png";
import proposterousFridge from "../../img/proposterousFridge.png";
import proposterousOldCode from "../../img/proposterousOldCode.jpg";
import proposterousMonitor from "../../img/proposterousMonitor.png";
import proposterousCounter from "../../img/proposterousCounter.jpg";
import proposterousPreAlphaDevLog1 from "../../img/proposterousPreAlphaDevLog1.png";
import proposterousPreAlphaDevLog2 from "../../img/proposterousPreAlphaDevLog2.png";

// Lite Squad Images
import liteSquadImg from "../../img/litesquad.png";
import litesquadReadyingUp from "../../img/litesquadReadyingUp.png";
import litesquadChangingColors from "../../img/litesquadChangingColors.png";
import litesquadCustomizing from "../../img/litesquadCustomizing.png";
import litesquadPickups from "../../img/litesquadPickups.jpg";
import litesquadPrototyping from "../../img/litesquadPrototyping.png";
import litesquadCharacterConceptArt from "../../img/litesquadCharacterConceptArt.png";
import litesquadZombie from "../../img/litesquadZombie.jpg";

// Toady I Learned Images
import toadyImg from "../../img/toady.png";
import toadyilearnedSpawn from "../../img/toadyilearnedSpawn.png";
import toadyilearnedLilyPad from "../../img/toadyilearnedLilyPad.png";
import toadyilearnedEnemyFish from "../../img/toadyilearnedEnemyFish.png";
import toadyilearnedEndScreen from "../../img/toadyilearnedEndScreen.png";
import toadyilearnedFirstPlace from "../../img/toadyilearnedFirstPlace.png";

// BitterGaming Images
import bittergamingImg from "../../img/bittergaming.jpeg";
import bittergamingIsland from "../../img/bittergamingIsland.jpg";
import bittergamingFreshDarkRPServer from "../../img/bittergamingFreshDarkRPServer.jpg";
import bittergamingHoboArea from "../../img/bittergamingHoboArea.jpg";
import bittergamingScavenge from "../../img/bittergamingScavenge.jpg";
import bittergamingTTTServer from "../../img/bittergamingTTTServer.jpg";
import bittergamingNewTTTServer from "../../img/bittergamingNewTTTServer.jpg";


// import archivesImg from "../../img/archives.png"; not in use

import WorkItem from '../WorkItem';
import WorkItemHeader from '../WorkItemHeader';




export const Wrapper = styled.div`
    width: 100%;
    background: var(--darkGray);
`;

export const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Top = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 2;
    padding: 0 20%;
    padding-bottom: 6rem;
    transition: all 0.5s;
    transform: translateY(${(props) => (props.workIsOn ? '0%' : '30%')});
    opacity: ${(props) => (props.workIsOn ? 1 : 0)};
    z-index: 5;
    @media ${brk.device.tablet}{
        padding: 0 12%;
    }
    @media ${brk.device.pc}{
        padding: 0 12%;
    }
`;

export const Mid = styled.div`
    display: flex;
    flex: 16;
    align-items: center;
    justify-content: center;
`;


export const Bottom = styled.div`
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: center;
    z-index: 2;
`;

export const BottomSVG = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    img {
        position: absolute;
        bottom: -1px;
        width: 100%;
        z-index: 2;
    }
`;


function Work(props) {


    const [workIsOn, setWorkIsOn] = useState(false);
    const [expandedWorkItem, setExpandedWorkItem] = useState(-1);

    const { setShowingContentModal, showingContentModal, displayedContent, setDisplayedContent } = props;


    const listener = e => {
        let y = window.scrollY;
        let maxH = document.body.scrollHeight - window.innerHeight;
        let curScroll = y / maxH;
        if (curScroll >= .30) { // Work Section
            if (!workIsOn) {
                setWorkIsOn(true);
            }
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    });


    return (
        <>
            <Wrapper id="work">
                <Content>
                    <Top
                        workIsOn={workIsOn}
                    >
                        <WorkItemHeader Text={"My Work"} />
                        <WorkItem 
                            title={"Jetson"}
                            url={"www.jetson.app"}
                            shortDesc={"A Gamified, AI-Powered Entrepreneurship Platform"}
                            toolsUsed={["Clever.", "Inspect", "Element", "IsYourBest", "Friend", "IGuess!", "ToolsHidden", "Because", "OfPossible", "NDA", "LetsSetUp", "AnInterview"]}
                            src={jetsonLogo}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={12}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"Jetson is a gamified entrepreneurship platform that uses AI to help users start and grow their businesses."}
                            lootText={"I've always been interested in working with AI tooling outside of my personal projects. This was a great opportunity to learn more about AI and how it can be used to empower the next generation of entrepreneurs."}
                            contentData={[
                                {
                                    img: jetsonGamified,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Money Machine",
                                },
                                {
                                    img: jetsonAstro,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Gigs",
                                },
                                {
                                    img: jetsonChooseYourBusiness,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "The Guardians",
                                }
                            ]}
                        />
                        <WorkItem 
                            title={"Blockchains Inc."}
                            url={"www.blockchains.com"}
                            shortDesc={"A Web3 Business Platform."}
                            toolsUsed={["Clever.", "Inspect", "Element", "IsYourBest", "Friend", "IGuess!", "ToolsHidden", "Because", "OfPossible", "NDA", "LetsSetUp", "AnInterview"]}
                            src={blockchainsHero}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={11}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"Blockchains Inc. is building powerful and innovative proprietary technology to support the next generation of the internet. As a Web Engineer, I was responsible for the delivery of major features and improvements to the Blockchains platform."}
                            lootText={"Initially being unfamiliar yet interested in Web3 and its real-world application, pushing a production launch led to me learning a lot about the blockchain and its potential. My primary role here was ensuring an immersive and seamless user experience."}
                            contentData={[
                                {
                                    img: blockchainsGadgets,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Money Machine",
                                },
                                {
                                    img: blockchainsChargeTower,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Gigs",
                                },
                                {
                                    img: blockchainsPeople,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "The Guardians",
                                }
                            ]}
                        />
                        <WorkItem 
                            title={"KeplerSavings"}
                            url={"www.keplersavings.com"}
                            shortDesc={"The retirement savings account that stays with you."}
                            toolsUsed={["Clever.", "Inspect", "Element", "IsYourBest", "Friend", "IGuess!", "ToolsHidden", "Because", "OfPossible", "NDA", "LetsSetUp", "AnInterview"]}
                            src={keplerDashboard}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={10}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"KeplerSavings is a retirement savings SaaS that is designed to be a long-term retirement solution for the user. I was the founding engineer and was responsible for the entire tech stack. I was also responsible for the design and implementation of the entire front-end and back-end."}
                            lootText={"Having worked in FinTech in the past, I was able to leverage that experience to build a highly scalable, highly available SaaS product."}
                            contentData={[
                                {
                                    img: keplerFinalAmount,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Final Retirement Amount",
                                },
                                {
                                    img: keplerSpendingHabits,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Spending Habits",
                                },
                                {
                                    img: keplerStartInvesting,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Start Investing",
                                }
                            ]}
                        />
                        <WorkItem 
                            title={"GravyStack"}
                            url={"www.gravystack.com"}
                            shortDesc={"A Banking App for Kids That Plays Like a Game."}
                            toolsUsed={["Clever.", "Inspect", "Element", "IsYourBest", "Friend", "IGuess!", "ToolsHidden", "Because", "OfPossible", "NDA", "LetsSetUp", "AnInterview"]}
                            src={gravyStackImg}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={9}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"GravyStack is a gamified mobile banking app for both kids and parents. I was hired as the first and only full-stack engineer tasked to bridge the gap in communication and tech between the frontend and backend teams."}
                            lootText={"Converting the way developers think and share ideas across a wide range of skillsets and tools was a gratifying challenge. It was in this role that for the first time I got to see Unity's limitations and bottlenecks first hand. I learned a ton about non-traditional integration strategies by helping create and establish a design pattern to accommodate a C# frontend working with a TypeScript backend."}
                            contentData={[
                                {
                                    img: gravyStackMoneyMachine,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Money Machine",
                                },
                                {
                                    img: gravyStackGigs,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Gigs",
                                },
                                {
                                    img: gravyStackGuardians,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "The Guardians",
                                },
                                {
                                    img: gravyStackHenchmen,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "The Henchmen",
                                },
                                {
                                    img: gravyStackWhatIs,
                                    isVideo: true,
                                    videoUrl: "https://www.youtube.com/embed/om1OnrZIni8",
                                    title: "What is GravyStack?",
                                },
                                {
                                    img: gravyStackTestimonial,
                                    isVideo: true,
                                    videoUrl: "https://www.youtube.com/embed/BFOpHo-k-1o",
                                    title: "GravyStack Parent Testimonial Kasaleks Family",
                                    
                                },
                            ]}
                        />
                        <WorkItem 
                            title={"OSRSGuessr"}
                            url={"www.osrsguessr.com"}
                            shortDesc={"A Runescape Map Knowledge Game."}
                            toolsUsed={["TypeScript", "React", "Node", "Express", "MongoDB", "Mongoose", "Python", "Styled", "REST API", "Git", "Heroku", "AWS S3", "React-Leaflet"]}
                            src={osrsguessrImg}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={0}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"This game exists purely because I've wanted to play something like it since I was a kid. I always felt more immersed in game worlds than anything else and wanted to challenge my knowledge of Runescape. For most of my personal projects, I prefer Unity -- but with this tech stack OSRSGuessr is accessible to anyone, for free, right in the browser."}
                            lootText={"At the time of writing this, over 150,000 games have been complete and almost 70,000 accounts have been registered. I was only anticipating around 100 accounts to be created. With this amount of unexpected traffic, OSRSGuessr required hot fixes, optimizations and upgrades around the clock for the first 48 hours. As a result, I feel much more confident with Disaster Recovery and providing real-time support."}
                            contentData={[
                                {
                                    img: osrsGuessrSoloRunStatsPage,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Tracking Player Stats",
                                },
                                {
                                    img: osrsGuessrAuthedHomePage,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Simple and Clear Design",
                                },
                                {
                                    img: osrsGuessrHomePage,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Analytics && Stats on Home Page",
                                },
                                {
                                    img: osrsGuessrAboutPage,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Community-Driven Design and Mission",
                                },
                                {
                                    img: osrsGuessrJimmy,
                                    isVideo: true,
                                    videoUrl: "https://www.youtube.com/embed/AjITXHdTHYc",
                                    title: "Jimmy's Raw Reaction to OSRSGuessr",
                                },
                                {
                                    img: osrsGuessrTellacon,
                                    isVideo: true,
                                    videoUrl: "https://www.youtube.com/embed/ISiAqU9lS4E",
                                    title: "Tellacon's Raw Reaction to OSRSGuessr",
                                    
                                },
                            ]}
                        />
                        <WorkItem 
                            title={"Poker Power"}
                            url={"www.pokerpower.com"}
                            shortDesc={"The Online Poker App That Empowers Women."}
                            toolsUsed={["Clever.", "Inspect", "Element", "IsYourBest", "Friend", "IGuess!", "ToolsHidden", "Because", "OfPossible", "NDA", "LetsSetUp", "AnInterview"]}
                            src={pokerpowerImg}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={1}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"I was hired to help bring the awesome vision of PokerPower to life. The primary motivation behind PokerPower is to teach women how to learn and utilize life skills through poker. Learning when to double down, how to bluff and knowing when you have the upper-hand are just a few ideals of many shared by the PokerPower team, teachers and students alike."}
                            lootText={"On a personal level, I strongly stand by the philosophy that PokerPower is cultivating. On a more technical level, I've learned so much more about proper networking etiquette, optimizing queries and leveraging faster technologies for delivering high-priority on-demand data right when users need it."}
                            contentData={[
                                {
                                    img: pokerpowerDontLeanIn,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Go All In.",
                                },
                                {
                                    img: pokerpowerWinningHand,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "We Have A Winner!",
                                },
                                {
                                    img: pokerpowerLessonsAreHere,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Lessons Are Here.",
                                },
                                {
                                    img: pokerpowerTakeYourSeat,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Take Your Seat.",
                                },
                                {
                                    img: pokerpowerHomeScreen,
                                    isVideo: false,
                                    videoUrl: "https://www.youtube.com/embed/AjITXHdTHYc",
                                    title: "Home Screen",
                                },
                                {
                                    img: pokerpowerChatOverlay,
                                    isVideo: false,
                                    videoUrl: "https://www.youtube.com/embed/ISiAqU9lS4E",
                                    title: "Chat With Your Friends!",
                                    
                                },
                            ]}
                        />
                        <WorkItem 
                            title={"Proposterous"}
                            url={null}
                            shortDesc={"A Prop-Hunt meets Ghostbusters party game."}
                            toolsUsed={["Unity", "C#", "JavaScript", "MongoDB", "Blender", "Substance Painter", "Git", "Photon", "UNet", "URP", "Photoshop", "Gimp"]}
                            src={proposterousImg}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={2}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"A game where you either play as ghosts or ghost hunters. As a ghost, you possess props and try to evade hunters. As hunters, your job is to exterminate all paranormal activity. I started this project to learn more about peer-to-peer networking with fully synchronized and accurate physics events; And it's just a fantastic and fresh spin-off of the old classic 'Prop-Hunt' game. Before I put the project on hold, I had up to 8 players in a single game with a full gameplay loop completed."}
                            lootText={"When I was still working on Proposterous, I was learning new ways to 'min-max' network optimization and user experience(UX). I found really creative ways to always make the player 'feel' like their inputs are being processed in real-time as if they were in a single-player game."}
                            contentData={[
                                {
                                    img: proposterousFirstLevel,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Beginnings of First Map",
                                },
                                {
                                    img: proposterousHomeScreen,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Very Place-holder Home Screen",
                                },
                                {
                                    img: proposterousMicrowave,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Social Media Microwave",
                                },
                                {
                                    img: proposterousFridge,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Social Media Fridge",
                                },
                                {
                                    img: proposterousOldCode,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Some old dusty code from handling lobby events",
                                },
                                {
                                    img: proposterousMonitor,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "'Game of the year 2021' - Our Artist",
                                },
                                {
                                    img: proposterousCounter,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "The Counter Created for the Break Room",
                                },
                                {
                                    img: proposterousPreAlphaDevLog1,
                                    isVideo: true,
                                    videoUrl: "https://www.youtube.com/embed/RVG4CAxlAwQ",
                                    title: "Very Early Development Video",
                                },
                                {
                                    img: proposterousPreAlphaDevLog2,
                                    isVideo: true,
                                    videoUrl: "https://www.youtube.com/embed/9tAqBNHW_qY",
                                    title: "Custom Physics Camera Collisions",
                                },
                            ]}
                        />
                        <WorkItem 
                            title={"Lite Squad"}
                            url={null}
                            shortDesc={"A Thematic Online Top-Down Monster Shooter."}
                            toolsUsed={["Unity", "C#", "JavaScript", "Blender", "Git", "Photon", "UNet", "URP", "Gimp"]}
                            src={liteSquadImg}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={3}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"A very thematic Online Multiplayer Top-Down Shooter when you team-up and play as kid's miniaturized night-lights to fight off nightmarish monsters throughout the night. Up to 4-players can play and complete levels by controlling and defending sleeping kids in their life-sized bedroom. I tinkered with this idea because I wanted to understand the challenges of creating a multiplayer experience with 'homemade' AI."}
                            lootText={"This project is where I solidified my understanding of creating compelling AI, synchronizing clients, physics and user experience(UX) best practices. Another big win was learning how to optimize 3D and 2D assets for performance as there are potentially over hundred assets on-screen at a time."}
                            contentData={[
                                {
                                    img: litesquadCharacterConceptArt,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Character Concept Art",
                                },
                                {
                                    img: litesquadZombie,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Zombie Concept Model",
                                },
                                {
                                    img: litesquadPickups,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Alpha Weapon Pickups w/ Rarity",
                                },
                                {
                                    img: litesquadChangingColors,
                                    isVideo: true,
                                    videoUrl: "https://www.youtube.com/embed/AB0_Q70VKpE",
                                    title: "Changing Teams(Colors)",
                                },
                                {
                                    img: litesquadReadyingUp,
                                    isVideo: true,
                                    videoUrl: "https://www.youtube.com/embed/R5zKn81Zov0",
                                    title: "Players Joining and Readying Up",
                                },
                                {
                                    img: litesquadCustomizing,
                                    isVideo: true,
                                    videoUrl: "https://i.gyazo.com/c6fd1cce851522c0bf6ff846e6170523.mp4",
                                    title: "Early Player Customization",
                                },
                                {
                                    img: litesquadPrototyping,
                                    isVideo: true,
                                    videoUrl: "https://i.gyazo.com/901820801382fd0e0707134777023aa5.mp4",
                                    title: "Enter Prototyping Phase",
                                },
                            ]}
                        />

                        <WorkItemHeader Text={"Game Jams"} />
                        <WorkItem 
                            title={"Toady I Learned"}
                            url={"fcolor04.itch.io/toady-i-learned"}
                            shortDesc={"Made in 48 Hours. Theme: Starting Small."}
                            toolsUsed={["Unity", "Unity Collab", "C#", "JavaScript", "Git", "Photoshop", "Gimp"]}
                            src={toadyImg}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={4}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"I wanted to put my team-building, project-blueprinting and time-management skill to the test. So, I made a game in 48 hours with a person I had never met before who was in a different timezone than me. After 48 hours this game was auto-submitted into a game-jam contest."
                            }
                            lootText={"We won 1st place in the contest with the highest ratings! We managed to squeeze in AI, music, SFX and an end-screen. I learned much more than I had anticipated and I plan on doing these short-term projects again in the future. It offers 5-10 minutes of playtime."}
                            contentData={[
                                {
                                    img: toadyilearnedSpawn,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Initial Spawn",
                                },
                                {
                                    img: toadyilearnedLilyPad,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Perspective Elements",
                                },
                                {
                                    img: toadyilearnedEnemyFish,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Enemy Fish AI",
                                },
                                {
                                    img: toadyilearnedEndScreen,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "End Game Screen",
                                },
                                {
                                    img: toadyilearnedFirstPlace,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "We Placed 1st!",
                                },
                            ]}
                        />

                        <WorkItemHeader Text={"Game Servers"} />
                        <WorkItem 
                            title={"BitterGaming"}
                            url={null}
                            shortDesc={"Top-Ranked GMod and Minecraft Servers."}
                            toolsUsed={["Lua", "FTP(FileZilla)", "MySQL", "Php", "HTML", "CSS", "Java", "JavaScript", "C++", "Blender", "Gimp", "LMMS", "Paypal Integ.", ]}
                            src={bittergamingImg}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={5}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"As a highschool student I created many successful Garrysmod and Minecraft servers. I maintained and added content to these servers on set development cycles. I also hand-picked an administrative team to help moderate and manage operations during my downtime. I implemented holiday events, user-specific events (such as birthdays), and custom-built a system that awarded players for their time spent on our network of servers."}
                            lootText={"Luckily, I got to learn all of the hard lessons of software engineering early with these servers. Disaster struck many times, and my disaster recovery skills and general confidence were bolstered by this. My servers consistently ranked in top 1-5% of the top-ranked servers in the world because of my community-driven approach to server management."}
                            contentData={[
                                {
                                    img: bittergamingIsland,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Custom Island",
                                },
                                {
                                    img: bittergamingHoboArea,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Community Created Hobo Area",
                                },
                                {
                                    img: bittergamingFreshDarkRPServer,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "A Fresh DarkRP Server",
                                },
                                {
                                    img: bittergamingNewTTTServer,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "New TTT Server",
                                },
                                {
                                    img: bittergamingScavenge,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "I Made 'Scavenge', A Custom Gamemode",
                                },
                                {
                                    img: bittergamingTTTServer,
                                    isVideo: false,
                                    videoUrl: null,
                                    title: "Old TTT Server",
                                },
                            ]}
                        />

                        {/* <WorkItemHeader Text={"Lost Archives"} />
                        <WorkItem 
                            title={"Graveyard"}
                            url={null}
                            startDate={"Beginning of Time"}
                            endDate={"End of Time"}
                            shortDesc={"Old, dusty fragments of previous knowledge are buried here."}
                            toolsUsed={[
                                "HTML", 
                                "CSS",
                                "Sass",
                                "JavaScript",
                                "React",
                                "REST API",
                                "Node.js",
                                "Express",
                                "MongoDB",
                                "Mongoose",
                                "React-Fiber",
                                "ThreeJS",
                                "Many More..",0
                            ]}
                            src={archivesImg}
                            setExpandedWorkItem={setExpandedWorkItem}
                            expandedWorkItem={expandedWorkItem}
                            workItemIndex={6}
                            setShowingContentModal={setShowingContentModal}
                            showingContentModal={showingContentModal}
                            displayedContent={displayedContent}
                            setDisplayedContent={setDisplayedContent}
                            questText={"Caution: What you're about to see is probably year(s) old and will feel out of context compared to some of my other projects. These projects were build with the sole purpose of learning and nothing else. When I determine that I have reached my learning potential with any given personal project, I will move onto something else to challenge me."}
                            lootText={"I've learned much from these projects. A lot of other old stuff was lost in translation, but there are some gems in here that I could find. Learning 3JS, React-Three-Fiber and VR for the browser was an absolute blast because I learned a lot about browser/gpu limitations and general practicality of the technology."}
                            contentData={[
                                {
                                    img: tile2Img,
                                    isVideo: false,
                                    videoUrl: null,
                                },
                                {
                                    img: tile3Img,
                                    isVideo: false,
                                    videoUrl: null,
                                },
                                {
                                    img: tile4Img,
                                    isVideo: true,
                                    videoUrl: "https://www.youtube.com/embed/9e81eOf0WS4",
                                },
                            ]}
                        /> */}
                    </Top>
                    <Bottom>
                        <BottomSVG>
                            <img src={WorkSVG} alt='workSVG'></img>
                        </BottomSVG>
                    </Bottom>
                </Content>
            </Wrapper>
        </>
    );
};

export default Work;
