import React from 'react';
import styled from "styled-components";

import brk from '../../util/breakpoints';

export const Wrapper = styled.div`
    padding-top: 6rem;
    width: 100%;
`;

export const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const LineContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
`;

export const Line = styled.div`
    height: 3px;
    width: 100%;
    border-radius: 6px;
    background: var(--white);
    display: flex;
    @media ${brk.device.tablet}{
        height: 2px;
    }
    @media ${brk.device.pc}{
        height: 2px;
    }
`;

export const TxtContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;
    text-align: center;
`;

export const Txt = styled.h1`
    height: 100%;
    text-align: center;
    font-size: var(--fontSuperBig);
    color: var(--white);
    font-family: var(--fontReg);
    font-weight: var(--fontWeightReg);
    @media ${brk.device.tablet}{
        font-size: var(--fontBig);
    }
    @media ${brk.device.pc}{
        font-size: var(--fontBig);
    }
`;

function WorkItemHeader(props) {

    return (
        <>
            <Wrapper>
                <Content>
                    <LineContainer>
                        <Line />
                    </LineContainer>
                    <TxtContainer>
                        <Txt>{props.Text}</Txt>
                    </TxtContainer>
                    <LineContainer>
                        <Line />
                    </LineContainer>
                </Content>
            </Wrapper>
        </>
    );
};

export default WorkItemHeader;
