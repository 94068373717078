import React, {useState, useEffect} from 'react';
import styled from "styled-components";

import { FaLinkedinIn, FaTwitter, FaFacebookF, FaInstagram} from "react-icons/fa";


import brk from '../../util/breakpoints';


import HeadshotImg from "../../img/headshot.jpg";
import AboutSVG from "../../img/about.svg";

export const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    padding: 3rem;
    background: var(--white);
    overflow: hidden;
    @media ${brk.device.tablet}{
        padding: 0rem;
    }
`;

export const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 5rem;
    transition: transform 0.33s, opacity 0.5s;
    transform: translateX(${(props) => (props.on ? '0%' : '-30%')});
    opacity: ${(props) => (props.on ? '1' : '0')};
    flex: 16;
    @media ${brk.device.tablet}{
        width: 100%;
        padding: 1rem;
    }
`;

export const LeftPicture = styled.div`
    display: flex;
    width: 256px;
    height: 256px;
    border-radius: 50%;
    overflow: hidden;
    background: red;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    @media ${brk.device.tablet}{
        width: 220px;
        height: 220px;
    }
`;
export const LeftBio = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    font-family: var(--fontReg);
    font-size: var(--fontSmall);
    h1 {
        text-align: center;
        font-family: var(--fontReg);
        font-size: var(--fontTiny);
        font-weight: var(--fontWeightBold);
        /* text-decoration: underline; */
        color: var(--black);
        margin-bottom: 1rem;
    }
    p {
        span {
            transition: color 0.25s;
            &:hover {
                color: var(--purple);
            }
        }
    }
    @media ${brk.device.tablet}{
        font-size: var(--fontTiny);
        width: 87.5%;
    }
`;

export const LeftSocials = styled.div`
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    a {
        font-size: var(--fontMed);
        text-decoration: none;
        color: var(--black);
        transition: transform 0.2s, color 0.2s;
        &:hover {
            transform: translateY(-10%);
            transform: scale(1.25);
            color: var(--purple);
        }
        @media ${brk.device.tablet}{
            font-size: var(--fontMed);
        }
    }
    @media ${brk.device.tablet}{
        width: 75%;
    }
`;

export const Mid = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media ${brk.device.tablet}{
        display: none;
    }
    @media ${brk.device.pc}{
        display: none;
    }
`;

export const MidDivider = styled.div`
    background: var(--purple);
    border-radius: 50px;
    height: ${(props) => (props.on ? '80%' : '0%')};
    width: 4px;
    transition: height 0.5s;
`;

export const Right = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    transition: transform 0.33s, opacity 0.5s;
    transform: translateX(${(props) => (props.on ? '0%' : '30%')});
    opacity: ${(props) => (props.on ? '1' : '0')};
    flex: 16;
    @media ${brk.device.tablet}{
        display: none;
    }
    @media ${brk.device.pc}{
        display: none;
    }
`;
export const RightSVGBox = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    img {
        position: absolute;
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`;

function About() {

    const [aboutIsOn, setAboutIsOn] = useState(0);


    const listener = e => {
        let y = window.scrollY;
        let maxH = document.body.scrollHeight - window.innerHeight;
        let curScroll = y / maxH;
        if (curScroll >= .15) { // About Section
            if (!aboutIsOn) {
                setAboutIsOn(1);
            }
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    });

    return (
        <>
            <Wrapper id="about">
                <Content>
                    <Left on={aboutIsOn}>
                        <LeftPicture>
                            <img alt='headshot' src={HeadshotImg} />
                        </LeftPicture>
                        <LeftBio>
                            <h1>
                                Senior Software Engineer
                            </h1>
                            <p>
                                I've had the opportunity to wear many hats throughout my career. Most recently, I've delivered multiple successful product launches for startups in various industries. I also have a special passion for building games and other gamified experiences.
                            </p>
                        </LeftBio>
                        <LeftSocials>
                            <a 
                            href='https://www.linkedin.com/in/jamescarlspears/' 
                            target={"_blank"}
                            rel='noreferrer'
                            ><FaLinkedinIn /></a>
                            <a 
                            href='https://twitter.com/JamesCarlSpears'                             
                            target={"_blank"}
                            rel='noreferrer'
                            ><FaTwitter /></a>
                            <a 
                            href='https://www.facebook.com/JamesCarlSpears/'
                            target={"_blank"}
                            rel='noreferrer'
                            ><FaFacebookF /></a>
                            <a 
                            href='https://www.instagram.com/jamescarlspears/'
                            target={"_blank"}
                            rel='noreferrer'
                            ><FaInstagram /></a>
                        </LeftSocials>
                    </Left>
                    <Mid>
                        <MidDivider on={aboutIsOn}/>
                    </Mid>
                    <Right on={aboutIsOn}>
                        <RightSVGBox>
                            <img alt='aboutmesvg' src={AboutSVG} />
                        </RightSVGBox>
                    </Right>
                </Content>
            </Wrapper>
        </>
    );
};

export default About;
