//import routes
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

// pages
import Home from "./Home";
import NotFound from "./NotFound";

function AllRoutes() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default AllRoutes;
